<template>
    


<div class="container">
                    <div class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid" id="kt_subheader">
                            <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                <!--begin::Info-->
                                    <div class="d-flex align-items-center flex-wrap mr-2">
                                        <div class="text-lg-right">
                                        </div>
                                    </div>

                                <div class="d-flex align-items-center flex-wrap mr-2">
                                    <!--begin::Page Title-->
                                    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Admin Company Manual Add & Connect</h5>
                                    <!--end::Page Title-->
                                    <!--begin::Action-->
                                    <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>

                                    <span class="text-muted font-weight-bold mr-4"> </span>
                                    <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>
                                    <!--end::Action-->
                                </div>
                                <!--end::Info-->
                            </div>
                    </div>


        <div class="row">
            <div class="col-lg-6">



 <v-card class="h-100"
    light
  >
    <v-card-title class="headline  lighten-3">
Choose Existing Buyer     </v-card-title>
    <v-card-text>
<h5>To make a new Partner click here  # </h5>   </v-card-text>


    <v-card-text>
            <v-autocomplete
        v-model="buyer"
        :items="buyerCompanies"
        :search-input.sync="buyerSearch"
        color="white"
        hide-no-data
        hide-selected
        item-text="company_name"
        item-value="id"
        placeholder="Start typing to Search"
        prepend-icon="mdi-database-search"
        return-object
      ></v-autocomplete>


    </v-card-text>
    
  </v-card>



            </div>

            <div class="col-lg-6">
      



                 <v-card class="h-100"
    light
  >
    <v-card-title class="headline  lighten-3">
       Search for  Existing Seller
    </v-card-title>
    <v-card-text>
      Select the Company you want to have auto related to.
    </v-card-text>



    <v-card-text>
   

      <v-autocomplete
        v-model="supplier"
        :items="supplierCompanies"
        :search-input.sync="supplierSearch"
        @change="supplierSelected(supplier)"
        color="white"
        hide-no-data
        hide-selected 
        item-text="company_name"
        item-value="id"
        label="Companies"
        placeholder="Start typing to Search"
        prepend-icon="mdi-database-search"
        return-object
      ></v-autocomplete>
    </v-card-text>
    <v-divider></v-divider>
 
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="grey darken-3"
        @click="$router.push('/admin/addbrand')"
      >
        Create
        <v-icon right>mdi-account-circle</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>



            </div>

        </div>


 <div class="row">
            <div class="col-lg-6">

<v-btn
        color="grey darken-3"
        @click="connect"
        v-if="!isConnected"
      >
        Connect
      </v-btn>
      
            </div>

            <div class="col-lg-6">
            </div>
 </div>
            









<v-overlay
  :absolute="true"
  :opacity="0.46"
  :value="isConnecting"
>
  <v-progress-circular indeterminate size="36"></v-progress-circular>
</v-overlay>


</div>





</template>

<script>
import Swal from "sweetalert2";
import { mapActions } from "vuex";
import gql from "graphql-tag";

export default {
  data() {
    return {
      buyer: null,
      buyerSearch: "",
      buyerCompanies: [],
      supplier: null,
      supplierSearch: "",
      supplierCompanies: [],
      isConnected: false,
      isConnecting: false
    };
  },
  apollo: {
    buyerCompanies: {
      query: gql`
        query Companies($search: String) {
          buyerCompanies: companies(
            where: {
              type: "buyer",
              company_name_contains: $search
            }) {
            id
            company_name
          }
        }
      `,
      variables() {
        return {
          search: this.buyerSearch == null ? '' : this.buyerSearch
        }
      },
    },
    supplierCompanies: {
      query: gql`
        query Companies($search: String) {
          supplierCompanies: companies(
            where: {
              type: "supplier",
              company_name_contains: $search
            }) {
            id
            company_name
          }
        }
      `,
      variables() {
        return {
          search: this.supplierSearch == null ? '' : this.supplierSearch
        }
      },
    },
  },
  methods: {
    ...mapActions([
        'submitApplication',
        'getApplicationTotalCount',
        'getCrmTotalCount',
        'submitCrm',
        'updateApplication'
    ]),
    connect() {
      if(this.buyer == undefined || this.buyer == null) {
        this.$bvToast.toast('buyer not selected.', {
          title: 'An Error Occured!',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true
        });
        return;
      }
      else if(this.supplier == undefined || this.supplier == null) {
        this.$bvToast.toast('Supplier not selected.', {
          title: 'An Error Occured!',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true
        });
        return;
      }
      this.isConnecting = true;
      this.getCrmTotalCount({
        params: "supplier_company="+ this.supplier.id +"&buyer_company="+ this.buyer.id
      })
      .then((resp) => {
        if(resp.data == 0) {
          this.submitCrm({
            supplier_company: this.supplier.id,
            buyer_company: this.buyer.id
          })
          .then((cres) => {
            this.getApplicationTotalCount({
              params: "supplier_company="+ this.supplier.id +"&buyer_company="+ this.buyer.id
            })
            .then((ares) => {
              if(ares.data == 0) {
                this.submitApplication({
                  supplier_company: this.supplier.id,
                  buyer_company: this.buyer.id,
                  status: 'approved',
                  crm: cres.data.id
                })
                .then((res) => {
                  this.isConnecting = false;
                  Swal.fire({
                    title: "",
                    text: "The application has been successfully updated!",
                    icon: "success",
                    confirmButtonClass: "btn btn-secondary"
                  });
                })
              }
              else {
                console.log(ares.data);
                this.updateApplication({
                  id: ares.data[0].id,
                  status: 'approved',
                  crm: cres.data.id
                })
                .then((res) => {
                  if(res.data != false) {
                    this.isConnecting = false;
                    Swal.fire({
                      title: "",
                      text: "The application has been successfully updated!",
                      icon: "success",
                      confirmButtonClass: "btn btn-secondary"
                    });
                  }
                })
              }
            })
          })
        }
        else {
          this.isConnecting = false;
          this.$bvToast.toast('Already connected companies!', {
            title: 'An Error Occured!',
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: true
          });
        }
      })
      .catch(() => {
        this.isConnecting = false;
        this.$bvToast.toast('An Error Occured!', {
          title: 'Error',
          variant: 'danger',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: true
        });
      })
    }
  }
};



</script>


